@import '~@stitch-fix/mode-style-system';

.container {
  max-height: 78vh;
  max-width: 1920px;
  margin: 0 auto;
  display: grid;
  grid-template-areas: 'stack';
  overflow: hidden;
}

.content {
  grid-area: stack;
  place-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 1rem;

  @include at-breakpoint-md {
    max-width: 54rem;
    margin: 0 2rem;
  }
}

.textShadow {
  text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.2);
}

.whiteContentBackground {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 1.5rem;
  @include at-breakpoint-md {
    padding: 2rem;
  }
}

.media {
  grid-area: stack;
  max-height: 78vh;
}
