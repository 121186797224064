@import '~@stitch-fix/mode-style-system';

.carouselContainer {
  display: flex;
  // inverse the gutters of the container so that the carousel extends to the right edge of the viewport
  margin-right: -1rem;

  &.leftAlign {
    justify-content: flex-start;
  }
  &.centerAlign {
    justify-content: center;
  }

  // HACK overrides a Mode React style to implement the preferred carousel slide behavior
  // #design-system-eng https://stitchfix.slack.com/archives/CLPDWN5FC/p1680560503614529
  // mode-react breaking change first introduced in v25 https://github.com/stitchfix/mode-react/releases/tag/v25.0.0
  :global(.slider-list) {
    padding-right: 1rem;

    @include at-breakpoint-md {
      padding-right: 0;
    }
  }
}

.slideContainer {
  position: relative;
  display: block;

  &.hasLink {
    margin-block: 4px;

    // Allow clicking play/pause button but not video
    video {
      pointer-events: none;
    }
  }
}

.interactiveLink {
  @include interactive-focus;
}

.videoTextContent {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.hasLink {
  .imageContainer {
    position: relative;
  }

  .imageContainer,
  .videoTextContent {
    &::after {
      content: '';
      transition: background-color 400ms $motion-easing-default;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  &:hover {
    .imageContainer::after,
    .videoTextContent::after {
      background-color: rgba($color-white, 0.2);
    }
  }

  &:active {
    background-color: rgba($color-gray-16, 0.05);

    .imageContainer::after,
    .videoTextContent::after {
      background-color: rgba($color-gray-16, 0.05);
    }
  }
}

.labelContainer {
  position: absolute;
  bottom: 1.5rem;
  left: 1.5rem;
  right: 1.5rem;
  text-align: center;
  text-transform: uppercase;
  display: flex;
  justify-content: center;

  @include at-breakpoint-md {
    bottom: 3rem;
  }
}
