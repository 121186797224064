@import '~@stitch-fix/mode-style-system';

.container {
  padding: 2rem 0 1rem;

  @include at-breakpoint-md {
    padding: 2.5rem 0;
    max-width: 104.25rem;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 47.5% 52.5%;
    grid-template-rows: auto;
    grid-template-areas: 'content gallery';
    align-items: center;
  }
}

.content {
  padding: 0 1.5rem;
  max-width: 40rem;

  @include at-breakpoint-sm {
    padding: 0 3rem;
  }

  @include at-breakpoint-md {
    margin-right: 1rem;
    padding: 0 1.5rem;
    justify-self: end;
    align-items: center;
    grid-area: content;
  }
}

.eyebrow {
  margin-bottom: 0.5rem;

  @include at-breakpoint-sm {
    margin-bottom: 1rem;
  }
}

.title {
  margin-bottom: 1rem;
  max-width: 19rem;

  @include at-breakpoint-sm {
    max-width: 35rem;
  }
}

.description {
  @include body-copy-responsive-md;

  margin-bottom: 1rem;

  a {
    @include link-on-light;
  }
}

.cta {
  margin-bottom: 1.5rem;
}

// Note: horizontal padding needs to match the Carousel's gutter overflow
.media {
  grid-area: gallery;
  padding: 0 1.5rem;

  @include at-breakpoint-sm {
    padding: 0 3rem;
  }

  @include at-breakpoint-md {
    padding: 0;
  }
}

.imageContainer {
  background-color: $color-gray-95;

  // Design is sending us a lot of images that are slightly outside of the
  // correct proportions, causing a vertical scrollbar. While we commuinicate
  // with them that all images must be 1x or 2x these sizes, let's solve the
  // scrollbar problem in code.
  overflow: hidden;
  height: 327px;
  width: 327px;

  // This breakpoint matches Responsive Image when it switches to the desktop image
  @include at-breakpoint-lg {
    height: 417px;
    width: 417px;
  }
}

.image {
  opacity: 1;
  transition: opacity 300ms ease-in-out;
  height: 100%;
  align-items: center;
  display: flex;

  @include at-breakpoint-sm {
    opacity: 0;
  }

  &.inView {
    opacity: 1;
  }
}
