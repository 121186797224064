@import '~@stitch-fix/mode-style-system';

.gridItemContainer {
  position: relative;
}

.hasLink {
  @include interactive-focus;
  .imageContainer {
    &::after {
      content: '';
      transition: background-color 400ms $motion-easing-default;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
  &:hover {
    .imageContainer::after {
      background-color: rgba($color-white, 0.2);
    }
  }
  &:active {
    background-color: rgba($color-gray-16, 0.05);

    .imageContainer::after {
      background-color: rgba($color-gray-16, 0.05);
    }
  }
}

.labelContainer {
  position: absolute;
  bottom: 1.5rem;
  left: 1.5rem;
  right: 1.5rem;
  text-align: center;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  @include at-breakpoint-md {
    bottom: 3rem;
  }
}
