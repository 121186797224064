@import '~@stitch-fix/mode-style-system';

.container {
  display: grid;
  grid-template-columns: 60px 1fr;
  grid-template-rows: 1fr 1fr 120px;
  background-color: $color-gray-99;
  max-width: 644px;
  margin: 0 auto;
  isolation: isolate;

  @include at-breakpoint-md {
    background-color: $color-white;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    max-width: none;
  }
}

.media {
  grid-column: 1 / 3;
  grid-row: 1 / 4;
  overflow: hidden;

  @include at-breakpoint-md {
    grid-column: 1;

    &.textFirst {
      grid-column: 2;
    }
  }
}

.content {
  background: $color-white;
  grid-row: 3/5;
  grid-column: 2;
  padding: 1.5rem;
  z-index: 1;
  margin-bottom: 3rem;

  p:last-of-type {
    margin-bottom: 0;
  }

  @include at-breakpoint-md {
    grid-row: 1;
    grid-column: 2;
    padding: 0 3rem;
    margin-bottom: 0;
    align-self: center;

    &.textFirst {
      grid-column: 1;
    }
  }
}

.avatar {
  border-radius: 50%;
  overflow: hidden;
  width: 64px;
  height: 64px;
}
