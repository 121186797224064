@import '~@stitch-fix/mode-style-system';

.carouselContainer {
  margin: auto -16px;
  display: flex;
  justify-content: center;
  isolation: isolate;

  @include at-breakpoint-md {
    margin: auto;
  }
}

.imageSlideContainer {
  padding: 2.5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: #fff;

  @include at-breakpoint-md {
    justify-content: space-between;

    div:nth-child(1) {
      height: 100%;
    }
    img {
      object-fit: cover;
      width: 80%;
    }
  }
}

.imageLabelContainer {
  margin-top: 1rem;
}

.videoSlideContainer {
  position: relative;
  display: block;
  height: 100%;
}

.videoSlideContainer::after {
  content: '';
  position: absolute;
  height: 152px;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to top, #000, transparent);
  z-index: 1;
}

.videoLabelContainer {
  z-index: 2;
  position: absolute;
  bottom: 2.5rem;
  left: 2.5rem;
  right: 2.5rem;
}
