@import '~@stitch-fix/mode-style-system';

.container {
  max-width: 1920px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  isolation: isolate;
  overflow: hidden;
  max-height: 75vh;

  @include at-breakpoint-md {
    display: grid;
    max-height: 78vh;
    // set the template areas to be stacked so that the content sits on top of the media
    grid-template-areas: 'stack';

    &.splitView {
      // set the template areas to be split between the content and media so
      // they display side by side
      grid-template-areas: 'content media';
      grid-auto-columns: 1fr 1fr;
    }
  }
}

.title {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1.5rem;
}

.media {
  display: grid;
  grid-template-areas: 'stack';
  overflow: hidden;

  @include at-breakpoint-md {
    grid-area: stack;
    .splitView & {
      // set the grid area to be media so it can be displayed side by side
      grid-area: media;
    }
  }

  .mediaItem {
    // set the media item to stack so it can sit below the content
    grid-area: stack;
    width: 100%;
    max-width: 560px;
    margin: 0 auto;

    @include at-breakpoint-md {
      max-width: 100%;
      max-height: 78vh;
    }
  }
}

.content {
  place-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  padding: 1rem 1rem 2rem;
  z-index: 1;
  background: $color-gray-99;
  width: 100%;
  text-align: center;

  .splitView & {
    margin-top: unset;
    height: 100%;
    width: 100%;
  }

  @include at-breakpoint-md {
    grid-area: stack;
    width: auto;
    min-width: 424px;
    padding: 1.5rem 1.5rem 2.5rem;

    .splitView & {
      grid-area: content;
    }
  }
}

.rotator {
  display: grid;
  height: 3rem;
  overflow: hidden;
  width: 100%;
  margin-top: 0.5rem;
  text-align: center;
  white-space: nowrap;

  @include at-breakpoint-md {
    margin-top: 1rem;
    height: 3.75rem;
  }

  > * {
    text-transform: uppercase;
    grid-area: 1/-1;
  }
}
