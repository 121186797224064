.container {
  position: relative;
  height: 100%;

  // override cloudinary image poster css to expand to the parent containers width and height
  :global(.vjs-poster) {
    background-size: cover;
    background-position: top left;
  }

  // override cloudinary video css to prevent black bars on the top/bottom of the video
  :global(.video-js) {
    height: 100%;
    width: 100%;

    :global(.vjs-tech) {
      position: relative;
      object-fit: cover;
    }
  }

  // hide videos in percy to reduce snapshot flakes
  @media only percy {
    visibility: hidden;
  }
}

.video {
  display: block;
  width: 100%;
}

.playPauseButtonContainer {
  position: absolute;
  background: rgba(0, 0, 0, 0);
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 1;
  cursor: pointer;
}

.playPauseButton {
  position: absolute;
  bottom: 1rem;
  right: 1rem;

  &.leftAlign {
    right: auto;
    left: 1rem;
  }
}
