@import '~@stitch-fix/mode-style-system';

$bottomPadding: 1.5rem;

.stickyCtaContainer {
  padding: 1rem;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  transition: transform 0.5s ease;
  padding-bottom: $bottomPadding;

  @include at-breakpoint-sm {
    display: none;
  }

  &.shown {
    transform: translateY(0);
  }

  &.hidden {
    transform: translateY(calc(100% + $bottomPadding));
  }
}
