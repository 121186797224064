@import '~@stitch-fix/mode-style-system';

.textContainer {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
  flex-direction: column;
  column-gap: 0.5rem;
  row-gap: 0.5rem;

  @include at-breakpoint-sm {
    column-gap: 3rem;
    flex-direction: row;
  }
}
