@import '~@stitch-fix/mode-style-system';

.carouselContainer {
  display: flex;
  // inverse the gutters of the container so that the carousel extends to the right edge of the viewport
  margin-right: -1rem;

  &.leftAlign {
    justify-content: flex-start;
  }
  &.centerAlign {
    justify-content: center;
  }

  // HACK overrides a Mode React style to implement the preferred carousel slide behavior
  // #design-system-eng https://stitchfix.slack.com/archives/CLPDWN5FC/p1680560503614529
  // mode-react breaking change first introduced in v25 https://github.com/stitchfix/mode-react/releases/tag/v25.0.0
  :global(.slider-list) {
    padding-right: 1rem;

    @include at-breakpoint-md {
      padding-right: 0;
    }
  }
}
