@import '~@stitch-fix/mode-style-system';

.container {
  max-width: 1368px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;

  @include at-breakpoint-md {
    grid-template-columns: 1fr 1fr;
  }
}

.media {
  video {
    margin: 0 auto;
  }
}

.text {
  align-self: center;
  padding: 3rem 1rem 2rem;

  p:last-of-type {
    margin-bottom: 0;
  }

  &.textFirst {
    order: -1;
    @include at-breakpoint-md {
      order: 1;
    }
  }

  @include at-breakpoint-md {
    padding: 0 1.5rem;
    order: 0;
  }
}
