@import '~@stitch-fix/mode-style-system';

.container {
  width: 100%;
}

.indicatorsContainer {
  background: linear-gradient(rgba(37, 40, 45, 0.4), rgba(0, 0, 0, 0));
  height: 74px;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  padding: 1.5rem 1rem 3rem;

  .indicator {
    border-radius: 2px;
    height: 2px;
    background-color: rgba(255, 255, 255, 0.2);
  }

  .filled {
    background-color: $color-white;
  }
}

.controlRight {
  margin-right: 1rem;
}

.controlLeft {
  margin-left: 1rem;
}

.mobileCarousel {
  width: 100%;
}